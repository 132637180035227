import { ThunkDispatch, nanoid } from '@reduxjs/toolkit';

import { deleteCookie, getCookie, waitFor } from 'utils/helpers';

import { logoutAll3rdParties } from 'redux/apis/OG/user/utils';

import { OGApi, TAGS } from '..';
import { helpers } from '../../../../utils';
import { WEBVIEW_SOURCE_COOKIE_NAME, WebviewSourceEnum } from '../../../../utils/hooks/useUi';
import { DEBUG_COOKIE_NAME, KEMTAI_DISCLAIMER_COOKIE_NAME, USER_CRED_COOKIE_NAME } from './constants';
import {
  deleteSessionCookie,
  deleteTokenCookie,
  getSessionCookie,
  getTempSessionIdentifierCookie,
  getTokenCookie,
  setTempSessionIdentifierCookie,
} from './utils';

export const handleLogout = async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(OGApi.util.resetApiState());
  deleteCookie(USER_CRED_COOKIE_NAME);
  deleteCookie(KEMTAI_DISCLAIMER_COOKIE_NAME);
  deleteCookie(DEBUG_COOKIE_NAME);
  logoutAll3rdParties();
  await waitFor(1000);
  deleteSessionCookie();
  deleteTokenCookie();
  await waitFor(1000);
};

export const authApi = OGApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Logs the user in, using email and password
     */
    signIn: builder.mutation<{ id: string; token: string; session: string }, { email: string; password: string }>({
      query: (data) => {
        return {
          url: '/api/v1/member/login',
          method: 'POST',
          body: {
            username: data.email,
            password: data.password,
            update_user_session: 1,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { member_id: string; token: string }) => {
        return {
          id: responseData.member_id + '',
          token: responseData.token,
          session: getSessionCookie(),
        };
      },
      invalidatesTags: [
        TAGS.PAYMENT,
        TAGS.ADDRESS,
        TAGS.USER,
        TAGS.PATIENT,
        TAGS.BOOKING,
        TAGS.STORE_ORDER,
        TAGS.PROVIDER,
      ],
    }),
    /**
     * Creates a member account and logs the user in
     */
    signUp: builder.mutation<
      { id: string; token: string; session: string },
      {
        email?: string;
        firstName?: string;
        lastName?: string;
        password?: string;
        passwordRepeat?: string;
        mobile?: string;
        zip?: string;
        gender?: string;
        birthday?: string;
      }
    >({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const createResult = await fetchWithBQ({
          url: '/api/v1/member/create',
          method: 'POST',
          body: {
            email: _arg.email,
            fname: _arg.firstName,
            lname: _arg.lastName,
            password: _arg.password,
            password1: _arg.passwordRepeat,
            mobile: _arg.mobile,
            zip: _arg.zip,
            transaction_terms: 1,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        if (createResult.error) return { error: createResult.error };

        if (_arg.gender) {
          await fetchWithBQ({
            url: '/api/v1/member/update/gender',
            method: 'POST',
            body: {
              sex: _arg.gender,
            },
            validateStatus: (response, result) => response.status === 200 && !result.errors,
          });
        }

        if (_arg.birthday) {
          await fetchWithBQ({
            url: '/api/v1/member/update/birthday',
            method: 'POST',
            body: {
              birthday: _arg.birthday,
            },
            validateStatus: (response, result) => response.status === 200 && !result.errors,
          });
        }

        return createResult?.data
          ? {
              data: {
                id: createResult.data.id + '',
                token: getTokenCookie(),
                session: getSessionCookie(),
              },
            }
          : { error: createResult.error };
      },
      invalidatesTags: [
        TAGS.PAYMENT,
        TAGS.ADDRESS,
        TAGS.USER,
        TAGS.PATIENT,
        TAGS.BOOKING,
        TAGS.STORE_ORDER,
        TAGS.PROVIDER,
      ],
    }),
    /**
     * Creates a member account and logs the user in
     */
    signUpFromPartial__deprecated: builder.mutation<
      { id: string; session: string; token: string },
      {
        email?: string;
        firstName?: string;
        lastName?: string;
        password?: string;
        passwordRepeat?: string;
        mobile?: string;
        zip?: string;
        gender?: string;
      }
    >({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const createResult = await fetchWithBQ({
          url: '/api/v1/member/update/update_signup',
          method: 'POST',
          body: {
            email: _arg.email,
            password: _arg.password,
            password1: _arg.passwordRepeat,
            transaction_terms: 1,
            temp_member: 1,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        if (createResult.error) return { error: createResult.error };

        if (_arg.mobile || _arg.zip || _arg.gender) {
          const dataMobileZipGender = {};
          if (_arg.mobile) dataMobileZipGender['mobile'] = _arg.mobile;
          if (_arg.zip) dataMobileZipGender['zip'] = _arg.zip;
          if (_arg.gender) dataMobileZipGender['sex'] = _arg.gender;
          await fetchWithBQ({
            url: '/api/v1/member/update',
            method: 'POST',
            body: {
              ...dataMobileZipGender,
            },
            validateStatus: (response, result) => response.status === 200 && !result.errors,
          });
        }

        if (_arg.firstName || _arg.lastName) {
          const dataNames = {};
          if (_arg.firstName) dataNames['fname'] = _arg.firstName;
          if (_arg.lastName) dataNames['lname'] = _arg.lastName;
          await fetchWithBQ({
            url: '/api/v1/member/update/name',
            method: 'POST',
            body: {
              ...dataNames,
            },
            validateStatus: (response, result) => response.status === 200 && !result.errors,
          });
        }

        return createResult?.data
          ? {
              data: {
                id: createResult?.data?.id + '',
                token: getTokenCookie(),
                session: getSessionCookie(),
              },
            }
          : { error: createResult.error };
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
      invalidatesTags: [
        TAGS.PAYMENT,
        TAGS.ADDRESS,
        TAGS.USER,
        TAGS.PATIENT,
        TAGS.BOOKING,
        TAGS.STORE_ORDER,
        TAGS.PROVIDER,
      ],
    }),

    /**
     * Creates a temporary session for temp users created while going through flows
     */
    createPartialSession: builder.mutation<{ id: string; token: string; session: string }, void>({
      query: () => {
        const sessionIdentifier = getTempSessionIdentifierCookie() || nanoid();
        setTempSessionIdentifierCookie(sessionIdentifier, { expires: new Date(Date.now() + 7 * 86400 * 1000) });
        return {
          url: '/api/v1/member/create/temp',
          method: 'POST',
          body: {
            ifa: sessionIdentifier,
            ifv: sessionIdentifier,
          },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.errors;
          },
        };
      },
      transformResponse: (responseData: { temp: { id: number | string; token: string } }) => {
        return {
          id: responseData.temp.id + '',
          token: responseData.temp.token,
          session: getSessionCookie(),
        };
      },
      invalidatesTags: [
        TAGS.PAYMENT,
        TAGS.ADDRESS,
        TAGS.USER,
        TAGS.PATIENT,
        TAGS.BOOKING,
        TAGS.STORE_ORDER,
        TAGS.PROVIDER,
      ],
    }),

    /**
     * Create a full account for the current temporary user
     */
    signUpFromPartial__new: builder.mutation<
      { id: string; token: string; session: string },
      {
        member: {
          email: string;
          password: string;
          firstName?: string;
          lastName?: string;
          mobile?: string;
          gender?: string;
          zipCode?: string;
        };
        options?: {
          updateTerms?: boolean;
          forChairEventFlow?: boolean;
          forConcierge?: boolean;
        };
      }
    >({
      query: ({ member, options = {} }) => {
        const { email, password, firstName, lastName, mobile, gender, zipCode } = member;
        const { updateTerms, forChairEventFlow, forConcierge } = options;
        return {
          url: '/api/v1/member/update/update_signup',
          method: 'POST',
          body: {
            email,
            password,
            password1: password,
            transaction_terms: updateTerms || false,
            chair_event_flow: forChairEventFlow || false,
            concierge: forConcierge || false,
            temp_member: 1,
            ...(firstName ? { fname: firstName } : {}),
            ...(lastName ? { lname: lastName } : {}),
            ...(mobile ? { mobile } : {}),
            ...(gender ? { sex: gender } : {}),
            ...(zipCode ? { zip: zipCode } : {}),
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: any) => {
        return {
          id: responseData?.data?.id + '',
          token: getTokenCookie(),
          session: getSessionCookie(),
        };
      },
      invalidatesTags: [
        TAGS.PAYMENT,
        TAGS.ADDRESS,
        TAGS.USER,
        TAGS.PATIENT,
        TAGS.BOOKING,
        TAGS.STORE_ORDER,
        TAGS.PROVIDER,
      ],
    }),

    /**
     * Verify one time access token. This token is used in tokenized url
     * of flows, such as the Consent Flow
     */
    verifyOneTimeAccessToken: builder.mutation<
      { decoded: { [key: string]: any }; token?: string },
      { token: string; verifyOnly?: boolean; identityVerificationData?: { birthdate?: string } }
    >({
      query: ({ token, verifyOnly, identityVerificationData }) => {
        const { birthdate } = identityVerificationData || {};
        return {
          url: '/api/v1/member/verify_one_time_access',
          method: 'POST',
          body: {
            one_time_access_token: token,
            verify_only: verifyOnly,
            ...(!verifyOnly ? { data: { birthdate } } : {}),
          },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.errors && (!verifyOnly || result.response?.valid);
          },
        };
      },
      transformResponse: (responseData: { one_time_access_info: any; token?: string }) => {
        return {
          decoded: responseData?.one_time_access_info || {},
          token: responseData?.token,
        };
      },
      invalidatesTags: (result, error, arg) =>
        arg.verifyOnly
          ? []
          : [TAGS.PAYMENT, TAGS.ADDRESS, TAGS.USER, TAGS.PATIENT, TAGS.BOOKING, TAGS.STORE_ORDER, TAGS.PROVIDER],
    }),

    /**
     * Logging out the user.
     * We specify "id" as null to easily handle it as part of utils matchers
     */
    logout: builder.mutation<{ id: null }, void>({
      queryFn: async (_arg, { dispatch }, _extraOptions, fetchWithBQ) => {
        await fetchWithBQ({
          url: '/logout',
          method: 'GET',
        });
        await handleLogout(dispatch);
        return { data: { id: null } };
      },
    }),
    logoutAndRefresh: builder.mutation<{ id: null }, void>({
      queryFn: async (_arg, { dispatch }, _extraOptions, fetchWithBQ) => {
        await fetchWithBQ({
          url: '/logout',
          method: 'GET',
        });
        await handleLogout(dispatch);
        if (
          ![WebviewSourceEnum.Android, WebviewSourceEnum.IOS].includes(getCookie(WEBVIEW_SOURCE_COOKIE_NAME)) &&
          !helpers.onLocalEnvironment()
        )
          window.location.reload();
        return { data: { id: null } };
      },
    }),
  }),
});

export const {
  // Mutations
  useSignInMutation,
  useSignUpMutation,
  useSignUpFromPartial__deprecatedMutation,
  useSignUpFromPartial__newMutation,
  useLogoutMutation,
  useCreatePartialSessionMutation,
  useVerifyOneTimeAccessTokenMutation,
} = authApi;
