import { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import ErrorSomethingWentWrong from 'screens/Errors/SomethingWentWrong';

import { routes } from 'utils';
import { useAuth, useUser } from 'utils/hooks';

import { useNavigate } from '@zeel-dev/zeel-ui';
import { LoadingOverlay } from 'components/common';

import { getCookie } from '../../utils/helpers';
import useUi, { WEBVIEW_SOURCE_COOKIE_NAME } from '../../utils/hooks/useUi';

type accountTypesLabels =
  | 'admin'
  | 'appliedConcierge'
  | 'concierge'
  | 'fullMember'
  | 'hipaa'
  | 'member'
  | 'currentOrPastZeelot'
  | 'provider'
  | 'spa'
  | 'va';
type Props = {
  children: ReactNode | ReactNode[];
  redirectTo?: string;
  allowTemp?: boolean;
  isType?: accountTypesLabels;
  omitReferrer?: boolean;
};

const RequireAuth: FC<Props> = ({ children, redirectTo, allowTemp, isType, omitReferrer }) => {
  const { isAuthenticated, isLoggingOut } = useAuth();
  const { fromMobile } = useUi();
  const {
    isTempMember,
    isLoading,
    isAdmin,
    isAppliedConcierge,
    isConcierge,
    isFullMember,
    isHipaa,
    isMember,
    isOrWasZeelot,
    isProvider,
    isSpa,
    isVa,
  } = useUser();
  const navigate = useNavigate();
  const [redirecting, setRedirecting] = useState<boolean>(false);

  const types = useMemo(() => {
    return {
      admin: isAdmin,
      appliedConcierge: isAppliedConcierge,
      concierge: isConcierge,
      fullMember: isFullMember,
      hipaa: isHipaa,
      member: isMember,
      currentOrPastZeelot: isOrWasZeelot,
      provider: isProvider,
      spa: isSpa,
      va: isVa,
    };
  }, [
    isType,
    isAdmin,
    isAppliedConcierge,
    isConcierge,
    isFullMember,
    isHipaa,
    isMember,
    isOrWasZeelot,
    isProvider,
    isSpa,
    isVa,
  ]);
  const notAllowed = !isAuthenticated || (isTempMember && !allowTemp) || (isType && !types[isType]);

  useEffect(() => {
    if (isLoading || isLoggingOut || redirecting) return;
    if (notAllowed) {
      console.error('RequireAuth Route:', 'notAllowed', { isAuthenticated, isTempMember, allowTemp, isType, types });
      if (!fromMobile) {
        console.log('RequireAuth Route:', 'redirecting to', redirectTo || routes.SIGN_IN({ host: true }));
        setRedirecting(true);
        navigate(
          `${redirectTo || routes.SIGN_IN({ host: true })}${omitReferrer ? '' : `?referrer=${window.location.href}`}`
        );
      } else {
        console.log(
          'RequireAuth Route:',
          `skipping redirect due to webview source mobile (value: ${getCookie(WEBVIEW_SOURCE_COOKIE_NAME)})`
        );
      }
    }
  }, [isLoading, notAllowed, isLoggingOut]);

  if (isLoggingOut) return <LoadingOverlay visible />;
  if (fromMobile && notAllowed) return <ErrorSomethingWentWrong />;
  if (isLoading || notAllowed) return <LoadingOverlay visible />;
  return <>{children}</>;
};

export default RequireAuth;
