import { FC, useCallback, useMemo, useState } from 'react';

import { helpers, routes } from 'utils';
import { usePage } from 'utils/hooks';

import { PaymentUsedFor } from 'redux/apis/OG/payment';
import { useCreateGigBookingMutation, useGetClientListQuery } from 'redux/apis/OG/spa';
import { getDefault } from 'redux/apis/OG/user';
import { Steps, useSpaSelfServeFlowSlice } from 'redux/features/spa/selfServe';

import { InformationBoldIcon, useModals, useNavigate } from '@zeel-dev/zeel-ui';

import { PMCProps } from '../../../../components/PaymentMethodCollector';
import { BoxItem, Button, HeadingInfo } from '../../../../components/common';
import ModalPaymentMethods from '../../../../components/modals/items/PaymentMethods';
import ModalPromosAdd from '../../../../components/modals/items/PromosAdd';
import Layout, { Body, Cell, Row } from '../../components/Layout';
import SpaCart from '../../components/SpaCart';
import { useSpaSelfServeEvent } from '../../index';
import ModalGratuity from '../../modals/Gratuity';
import styles from './style.module.scss';

/**
 * Checkout step for the Spa Self-Serve flow. Used to collect payment information and
 * create multiple booking requests for every request in the cart.
 */
const Checkout: FC = () => {
  const { openModal } = useModals();
  const navigate = useNavigate();
  const { logEvent } = useSpaSelfServeEvent();
  const {
    actions: { paymentMethodIdSelected, completed },
    selectors: {
      useRequestsSelector,
      usePaymentMethodIdSelector,
      useBookingPayloadSelector,
      useCollectedInformationSelector,
      useEmailSelector,
    },
  } = useSpaSelfServeFlowSlice();

  const [fetchingPricing, setFetchingPricing] = useState<boolean>(false);
  const [pricingVersionHash, setPricingVersionHash] = useState<number>(0);

  /** Queries **/
  const [submitRequestsMutation] = useCreateGigBookingMutation();
  const { data: clientsList } = useGetClientListQuery({ bookingType: 'spa_self_serve' });

  /** Selectors **/
  const collectedEmail = useEmailSelector();
  const paymentMethodId = usePaymentMethodIdSelector();
  const requests = useRequestsSelector();
  const bookingPayload = useBookingPayloadSelector();
  const collectedInformation = useCollectedInformationSelector();

  /** States **/
  const [submitError, setSubmitError] = useState<string | null>(null);

  /** Memoized values **/
  const selectedSpaClientObject = useMemo(() => {
    let singleClientId = null;
    (requests || []).forEach((r) => {
      if (singleClientId && singleClientId !== r.spa?.clientId) singleClientId = null;
      else singleClientId = r.spa?.clientId || null;
    });
    if (!singleClientId) return undefined;
    return clientsList?.find((c) => c.id === singleClientId);
  }, [requests, clientsList]);

  /** Effects **/
  const { isLoading, paymentMethods, user } = usePage(
    async ({ _paymentMethods }) => {
      if (!requests?.length) {
        return navigate(routes.SPA_SELF_SERVE.REQUESTS(), { replace: true });
      }

      const defaultPaymentMethod = getDefault(_paymentMethods);
      const paymentMethod = _paymentMethods.find((a) => a.id === paymentMethodId);
      if (!paymentMethod && defaultPaymentMethod) {
        await paymentMethodIdSelected(defaultPaymentMethod.id);
      }
    },
    {
      include: ['paymentMethods'],
      paymentContext: {
        usedFor: PaymentUsedFor.SpaBooking,
      },
    }
  );

  /** Handlers **/
  const handlePaymentCallback = useCallback<PMCProps['onSuccess']>(
    async (payload) => {
      if (!payload) return;
      paymentMethodIdSelected(payload.data?.id);
    },
    [paymentMethods]
  );

  const openPaymentMethodModal = useCallback(() => {
    openModal(
      {
        element: (
          <ModalPaymentMethods
            pmc={{
              usedFor: PaymentUsedFor.AtWorkBooking,
              config: {
                list: {
                  selectedId: paymentMethodId,
                  setSelectedAsDefault: true,
                },
              },
            }}
          />
        ),
      },
      handlePaymentCallback
    );
  }, [paymentMethodId, paymentMethods, handlePaymentCallback]);

  const handleGratuityModal = useCallback(() => {
    openModal(
      {
        element: <ModalGratuity />,
      },
      handlePaymentCallback
    );
  }, []);

  const onPromo = useCallback(async () => {
    setFetchingPricing(true);
    setPricingVersionHash((prev) => prev + 1);
    setFetchingPricing(false);
  }, []);

  const proceed = useCallback(async () => {
    setSubmitError(null);
    try {
      const createdBookingIds = await submitRequestsMutation(bookingPayload).unwrap();
      if (collectedInformation?.address) {
        const _clientId = bookingPayload.bookings?.[0]?.client?.id;
        const _locationId = bookingPayload.bookings?.[0]?.location?.id;
        await logEvent({
          email: user?.email,
          address_1: collectedInformation.address.address1,
          address_2: collectedInformation.address.address2,
          city: collectedInformation.address.city,
          state: collectedInformation.address.state,
          company: collectedInformation.name,
          ein: collectedInformation.einNumber,
          mobile: user.mobile,
          first_name: user.firstName,
          last_name: user.lastName,
          member_id: user.id,
          client_id: _clientId,
          location_id: _locationId,
        });
      } else {
        const allClientLocationHash: string[] = [];
        bookingPayload.bookings.forEach((b) => {
          const h = `${b.client.id}__${b.location.id}`;
          if (!allClientLocationHash.find((a) => a === h)) allClientLocationHash.push(h);
        });
        const promises: any[] = [];
        allClientLocationHash.forEach((h) => {
          const [__clientId, __locationId] = h.split('__');
          promises.push(
            logEvent({
              email: user?.email,
              member_id: user.id,
              client_id: __clientId,
              location_id: __locationId,
            })
          );
        });
        try {
          await Promise.allSettled(promises);
        } catch (_e) {
          console.error(_e);
        }
      }

      completed();
      navigate(
        routes.SETTINGS.SPA_BOOKINGS.UPCOMING({
          search: {
            booker: collectedEmail ? 'new' : 'returning',
          },
        }),
        {
          state: {
            idToOpen: createdBookingIds?.[0],
            preselectedSpa: {
              clientId: bookingPayload.bookings?.[0]?.client?.id,
              locationId: bookingPayload.bookings?.[0]?.location?.id,
            },
          },
        }
      );
    } catch (errors) {
      helpers.scrollTop();
      setSubmitError(
        Object.values(errors)
          ?.map((e) => {
            return typeof e === 'object' ? 'An error occurred, please try again later.' : e;
          })
          ?.join(' ')
      );
    }
  }, [bookingPayload, user, collectedInformation, collectedEmail]);

  const isCheckoutValid = useMemo(() => {
    const payment = paymentMethods.find((p) => p.id === paymentMethodId);
    return payment && payment.statusLabel === 'good';
  }, [paymentMethodId, paymentMethods]);

  const paymentMethodObject = useMemo(() => {
    return paymentMethods.find((p) => p.id === paymentMethodId);
  }, [paymentMethods, paymentMethodId]);

  return (
    <Layout
      loading={isLoading || fetchingPricing}
      skeleton={isLoading || fetchingPricing}
      tags={{
        title: 'Spa Self-Serve - Checkout',
      }}
      step={Steps.Checkout}
      testId='spa-self-serve--checkout-step'
      showTimeline
      error={submitError}
      navProps={{
        ...(selectedSpaClientObject
          ? { dualLogo: { logoUrl: selectedSpaClientObject.logo, fallbackName: selectedSpaClientObject.name } }
          : {}),
        showCart: false,
      }}
      backAction={() => navigate(routes.SPA_SELF_SERVE.REQUESTS())}
    >
      <Body>
        <div className='flow-header'>
          <h1>Ready to book?</h1>
          <HeadingInfo
            items={[
              { icon: 'credit-card-crossed', content: 'No charge until therapist checks out' },
              { icon: 'clock', content: 'Cancel up to 4 hours in advance' },
              { icon: 'orientation-card', content: 'Only trusted, vetted therapists' },
            ]}
            wrap={false}
          />
        </div>
        <Row>
          <Cell style={{ marginBottom: 24 }}>
            <div className={styles.cartContainer}>
              <SpaCart
                cartProps={{
                  containerType: 'block',
                  toggled: true,
                  actions: undefined,
                  noHeader: true,
                  contentUnderTotal: (
                    <div className={styles.tipExplainer} onClick={handleGratuityModal}>
                      <InformationBoldIcon size={18} />
                      <span>IMPT: Read how gratuity works for self-serve spa staffing</span>
                    </div>
                  ),
                  style: { overflow: 'auto', height: 'auto' },
                }}
                onLoadingStateChange={(s) => setFetchingPricing(s)}
                pricingVersionHash={pricingVersionHash}
              />
            </div>
          </Cell>
          <Cell>
            <BoxItem
              onClick={() => openModal({ element: <ModalPromosAdd onPromoCallback={onPromo} /> })}
              title='Promo or Gift Card'
              iconType={'add'}
              testId={`spa-self-serve--summary-pricing-item--promo-or-gift-card`}
            />
            <BoxItem
              title={!paymentMethodObject ? `Add Payment Method` : 'Payment'}
              subtitle={
                !paymentMethodObject
                  ? 'Select your payment method and add details.'
                  : paymentMethodObject?.labelFull || paymentMethodObject?.label
              }
              onClick={openPaymentMethodModal}
              action={{ type: paymentMethodId ? 'edit' : 'add' }}
              state={paymentMethodObject && paymentMethodObject.statusLabel !== 'good' ? 'invalid' : 'valid'}
              showLeftIcon={false}
              testId={`spa-self-serve--payment-section`}
            />
            <Button
              type='accent'
              flex
              disabled={!isCheckoutValid || fetchingPricing}
              className={styles.submitButton}
              onClick={() => proceed()}
            >
              Submit Booking
            </Button>
          </Cell>
        </Row>
      </Body>
    </Layout>
  );
};

export default Checkout;
