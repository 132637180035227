import ReactGA from 'react-ga4';

import { isObject } from 'lodash';
import omitBy from 'lodash/omitBy';
import Cookies from 'universal-cookie';

import { GenderRequestedEnum } from 'redux/apis/OG/booking/types';
import { GenderEnum } from 'redux/apis/OG/user/types';

/**------------------------------------------------------------------**
 *                           Environment                              *
 **------------------------------------------------------------------**/

export const getCrossParams = <T>(crossKey: string): T => {
  let currentParams;
  try {
    const parsed = JSON.parse(localStorage.getItem(crossKey) || '{}');
    if (isObject(parsed)) {
      currentParams = parsed;
    }
  } catch (e) {
    console.error(e);
  }
  return currentParams as T;
};
export const setCrossParams = <T>(crossKey: string, params: T, options: { merge?: boolean } = {}) => {
  const { merge } = options;
  const currentParams = getCrossParams<T>(crossKey);
  localStorage.setItem(crossKey, JSON.stringify(merge ? { ...currentParams, ...params } : params));
};
export const deleteCrossParams = (crossKey: string) => {
  localStorage.removeItem(crossKey);
};

/**
 * Returns the current environment
 * @returns {string} The current environment name
 */
export const getEnvironment = (): string => process.env.REACT_APP_ENV;

/**
 * Checks if the current environment is the one passed as argument.
 * @param {string | string[]} envs - The environment or list of environment to check for
 * @returns {boolean} If the current env is (or includes) the provided env(s)
 */
export const onEnvironment = (envs: string | string[]): boolean => {
  const currentEnv = getEnvironment();
  return Array.isArray(envs) ? envs.includes(currentEnv) : envs === currentEnv;
};

/**
 * Checks to see if you are on local environment
 * @returns {boolean} If on local environment
 */
export const onLocalEnvironment = (): boolean => onEnvironment('local');

/**
 * Checks to see if you are on QA environment
 * @returns {boolean} If on QA environment
 */
export const onQaEnvironment = (): boolean => onEnvironment('qa');

/**
 * Checks to see if you are on any lower environment, meaning all development, integration
 * or testing environments that aren't production/production-like. This can be used to know
 * wether you should prefill forms with dummy data to speed up testing, which you wouldn't want
 * to do on production.
 * @returns {boolean} If on any lower environment
 */
export const onLowerEnvironment = (): boolean => onEnvironment(['local', 'develop', 'integration', 'qa']);

/**
 * Gets the current host. So if you are on qa.zeel.com/a/b/c, it will return 'https://qa.zeel.com'.
 * This is useful when needing to redirect to an OG page.
 * @returns {boolean} The host
 */
export const getCurrentHost = (): string => window.location.protocol + '//' + window.location.host;

/**
 * Checks to see if you are currently on the VA app, either because you are on the va subdomain
 * such as '*.va.zeel.com' or 'va.zeel.com', or if you are running in "va_mode" locally, to make
 * the app behaves as if you were on a va subdomain. This is used to determine which routes to load
 * in the router, since routes are different between wellness and va, and is also useful to disable
 * 3rd party tracking, or other functionalities that we don't want enabled for anything VA-related.
 * @returns {boolean} If on the VA app
 */
export const onVa = (): boolean => {
  return getSubDomain().includes('va') || ((window as any).va_mode === true && onLocalEnvironment());
};

/**
 * Checks if the app is currently injected under OG web-server. When we deploy the app in an s3 bucket,
 * it can either be accessed directly (via *.inhome.zeel.com), or it can be accessed via OG web-server
 * which loads the react app for specific paths (via *.zeel.com, like david.dev.zeel.com or qa.zeel.com).
 * This function detects if the app is currently injected in OG.
 * @returns {boolean} If the app is injected in OG
 */
export const isInjected = (): boolean => !!(window as any)?.zeel_inhome_global_data?.links_server;

/**
 * Extracts all the subdomain parts from the provided url
 * @param {string} [url=window.location.hostname] - The url to extract subdomains from
 * @returns {string[]} A list of all extracted subdomain parts
 */
export const getSubDomain = (url = window.location.hostname): string[] => {
  let _url = url;

  _url.replace(new RegExp(/^\s+/), ''); // START
  _url = _url.replace(new RegExp(/\s+$/), ''); // END
  _url = _url.replace(new RegExp(/\\/g), '/');
  _url = _url.replace(new RegExp(/^http:\/\/|^https:\/\/|^ftp:\/\//i), '');
  _url = _url.replace(new RegExp(/^www\./i), '');
  _url = _url.replace(new RegExp(/\/(.*)/), '');

  if (_url?.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
    _url = _url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), '');
  } else if (_url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
    _url = _url.replace(new RegExp(/\.[a-z]{2,4}$/i), '');
  }
  const parts = _url.split('.');
  parts.pop();
  return parts;
};

/**
 * Retrieves a cookie by name
 * @param {string} name - The name of the cookie to retrieve
 * @returns {any} The cookie
 */
export const getCookie = (name: string): any => {
  const cookies = new Cookies(document.cookie);
  return cookies.get(name) || '';
};

/**
 * Sets or updates a cookie
 * @param {string} name
 * @param {any} value
 * @param {Object} options - The options of the cookie to set
 * @property {string} [options.path=/] - The cookie path
 * @property {Date} [options.expires] - The cookie's expiration date
 * @property {string} [options.secure=true] - if the cookie is secure (https only)
 */
export const setCookie = (
  name: string,
  value: any = '',
  options?: { path?: string; expires?: Date; secure?: boolean }
) => {
  const { path = '/', expires, secure = true } = options || {};
  const cookies = new Cookies(document.cookie);
  cookies.set(name, value, { path, secure, expires });
};

export const deleteCookie = (name: string) => {
  const cookies = new Cookies(document.cookie);
  cookies.set(name, '', { expires: new Date(0) });
};

/**------------------------------------------------------------------**
 *                       Misc & Formatting                            *
 **------------------------------------------------------------------**/

/**
 * Sets user metadata for GTAG
 */
export const setGAUserData = (
  properties: Partial<{
    email?: string;
    sha256_email_address?: string;
    phone_number?: string;
    sha256_phone_number?: string;
    address?: Partial<{
      first_name?: string;
      sha256_first_name?: string;
      last_name?: string;
      sha256_last_name?: string;
      street?: string;
      city?: string;
      region?: string;
      postal_code?: string;
      country?: string;
    }>;
  }> = {}
) => {
  const data: any = {};
  if (properties.email) data.email = properties.email;
  if (properties.sha256_email_address) data.sha256_email_address = properties.sha256_email_address;
  if (properties.phone_number) data.phone_number = properties.phone_number;
  if (properties.sha256_phone_number) data.sha256_phone_number = properties.sha256_phone_number;
  if (properties.address) {
    if (properties.address.first_name) data.first_name = properties.address.first_name;
    if (properties.address.sha256_first_name) data.sha256_first_name = properties.address.sha256_first_name;
    if (properties.address.last_name) data.last_name = properties.address.last_name;
    if (properties.address.sha256_last_name) data.sha256_last_name = properties.address.sha256_last_name;
    if (properties.address.street) data.street = properties.address.street;
    if (properties.address.city) data.city = properties.address.city;
    if (properties.address.region) data.region = properties.address.region;
    if (properties.address.postal_code) data.postal_code = properties.address.postal_code;
    if (properties.address.country) data.country = properties.address.country;
  }
  if (Object.keys(data).length > 0) {
    try {
      ReactGA.gtag('set', 'user_data', data);
    } catch (e) {
      console.error('GTAG USER DATA ERROR', e);
    }
  }
};
/**
 *
 * @param {string[]} strings - The array of strings to join
 * @param {string} [separator=' '] - The separator to use
 * @returns {string} The joined string
 */
export const joinStrings = (strings, separator = ' '): string => (strings || []).filter(Boolean).join(separator);

/**
 * Checks if 2 javascript dates are the same
 */
export const isSameDate = (d1: Date, d2: Date) =>
  d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();

/**
 * Returns the icon name prop to use, based on the provider's gender
 * or requested gender.
 * @param {string} [gender=''] - The gender
 * @returns {string} The icon name
 */
export const getGenderIcon = (gender = ''): string => {
  switch (gender?.toLowerCase()) {
    case GenderEnum.Male:
    case GenderRequestedEnum.MaleOnly:
    case GenderRequestedEnum.MalePreferred:
      return 'avatar-male';
    case GenderEnum.Female:
    case GenderRequestedEnum.FemaleOnly:
    case GenderRequestedEnum.FemalePreferred:
      return 'avatar-female';
    default:
      return 'avatar-neutral';
  }
};

/**
 * Returns the label for the gender enum
 */
export const getGenderLabel = (gender = '', anyLabel = 'Any'): string => {
  switch (gender?.toLowerCase()) {
    case GenderEnum.Male:
      return 'Male';
    case GenderRequestedEnum.MaleOnly:
      return 'Male Only';
    case GenderRequestedEnum.MalePreferred:
      return 'Male Preferred';
    case GenderEnum.Female:
      return 'Female';
    case GenderRequestedEnum.FemaleOnly:
      return 'Female Only';
    case GenderRequestedEnum.FemalePreferred:
      return 'Female Preferred';
    case GenderRequestedEnum.Any:
      return anyLabel;
    default:
      return 'Unspecified';
  }
};

/**
 * Copies content to the clipboard. By default, it will try to use the
 * navigator.clipboard api, and then fallback to using execCommand if the
 * former failed.
 * @param {string} text - The text to copy to clipboard
 * @returns {Promise<void>}
 */
export const copyToClipboard = async (text: string): Promise<void> => {
  if (!navigator.clipboard) {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body?.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  }
  await navigator.clipboard.writeText(text);
};

/**
 * Will prevent the user from being able to scroll the page by hiding the
 * overflow of the html tag. This can be useful when modals are open, and
 * you want to prevent the page underneath the modal to scroll too.
 *
 * The "unfreeze" helper does the opposite by restoring the ability to scroll.
 */
export const freezeScroll = () => (document.getElementsByTagName('html')[0].style.overflow = 'hidden');
export const unfreezeScroll = () => (document.getElementsByTagName('html')[0].style.overflow = '');

/**
 * Checks if the string provided is a "full" url, meaning it includes http/https.
 * This is used to differentiate local redirections (e.g /hello), to external
 * hrefs (e.g https://twitter.com/something). It is also very useful while we're
 * still forced to inject in OG because we can force a "full" refresh for pages that
 * might exist in OG.
 * @param {string} href - The link to verify
 * @returns {boolean} If the link is "full" or not
 */
export const isLinkFull = (link: string): boolean => /(http:\/\/|https:\/\/)/g.test(link);

/**
 * Will format the input into a currency-like string (e.g $2.50).
 * @example formatCurrency(12)
 * // returns $12.00
 * @param {number|string} [amount=0] - The value to convert to currency
 * @param {string} [symbol=$] - The currency symbol to use
 * @param {number|string} [fallback=-] - The fallback to use if result is 0 or null
 * @returns {number|string} The currency output
 *
 * @TODO Need to improve this by adding proper currency internationalization, to determine
 * output format. Right now, it will just format in US format, so if we extend out offering
 * outside the US, we'll need to refactor.
 */
export const formatCurrency = (
  amount: number | string = 0,
  symbol?: string,
  fallback?: number | string
): number | string => {
  if (typeof amount === 'string') amount = parseFloat(amount);
  const formattedNum = amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });
  return amount && amount?.toFixed ? formattedNum : fallback || '-';
};

/**
 * Extracts the file extension from a filename
 * @example getFileExtension('poutine.jpg')
 * // returns 'jpg'
 * @param {string} filename - The filename
 * @returns {string} The file extension, without '.'
 */
export const getFileExtension = (filename: string) => filename?.split('.').pop();

/**
 * Waits for a specific amount of time, asynchronously.
 * @param {number} ms - The time to wait in miliseconds
 * @returns {Promise<void>}
 */
export const waitFor = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Generates a random number to be used for fake data, such as a fake email address, etc.
 * @param {number} n - The amount of numbers to generate
 * @returns {string} The random suffix
 */
export const generateRandomSuffix = (n: number): string => {
  const add = 1;
  let max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.
  if (n > max) return generateRandomSuffix(max) + generateRandomSuffix(n - max);
  max = Math.pow(10, n + add);
  const min = max / 10;
  const number = Math.floor(Math.random() * (max - min + 1)) + min;
  return ('' + number).substring(add);
};

/**
 * Gets the full path of an asset. If provided a 'full' url, it will simply return it
 * as-is, and if provided a partial path (e.g '/a/b/c'), it will construct the full CDN
 * asset url using the REACT_APP_ASSETS_URL environment variable. If the parameter provided
 * is a partial path and does not start with a '/', it will prefix it with '/' when constructing
 * the full CDN path
 * @param {string} src - The asset path to retrieve
 * @returns {string} The full CDN path
 */
export const getAssetPath = (partialSrc = ''): string => {
  return isLinkFull(partialSrc)
    ? partialSrc
    : `${process.env.REACT_APP_ASSETS_URL}${partialSrc[0] === '/' ? partialSrc : `/${partialSrc}`}`;
};

/**
 * A convenience helper to get the asset path of an image, without having to
 * know or specify the prefix that leads to images. (uses 'getAssetPath')
 * @param {string} partialSrc - The partial image src to reconstruct
 * @returns {string} The full image path from CDN
 */
export const getImagePath = (partialSrc = ''): string => {
  return getAssetPath(
    isLinkFull(partialSrc) ? partialSrc : `/images${partialSrc[0] === '/' ? partialSrc : `/${partialSrc}`}`
  );
};

/**
 * A convenience helper to get the asset path of a video, without having to
 * know or specify the prefix that leads to videos. (uses 'getAssetPath')
 * @param {string} partialSrc - The partial video src to reconstruct
 * @returns {string} The full video path from CDN
 */
export const getVideoPath = (partialSrc = ''): string => {
  return getAssetPath(
    isLinkFull(partialSrc) ? partialSrc : `/videos${partialSrc[0] === '/' ? partialSrc : `/${partialSrc}`}`
  );
};

/**
 * Checks if the width of the window corresponds to 'mobile' width.
 * @returns {boolean} If the width is 'mobile'
 *
 * @TODO Need to refactor this into a hook, and review how we're handling
 * media-queries in SCSS. This check is too broad. Also, since it doesn't
 * listen to resize events, the return value will become inaccurate post mount.
 */
export const isMobileWidth = (): boolean => window.innerWidth <= 768;

/**
 * Get the user zipcode using html5 browser geolocate api and googlemaps api
 */
export const getUserZipCode = async (): Promise<string | null> => {
  return new Promise((resolve) => {
    if (onLocalEnvironment()) return resolve(null);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latLng = { lat: position.coords.latitude, lng: position.coords.longitude };
          const geocoder = new window.google.maps.Geocoder();
          try {
            const { results } = await geocoder.geocode({ location: latLng });
            let postalCode = '';
            if (results?.[0]) {
              for (let i = 0; i < results[0].address_components.length; i++) {
                const types = results[0].address_components[i].types;
                for (let typeIdx = 0; typeIdx < types.length; typeIdx++) {
                  if (types[typeIdx] === 'postal_code') {
                    postalCode = results[0].address_components[i].long_name;
                    break;
                  }
                }
                if (postalCode !== '') break;
              }
            }
            return resolve(postalCode.slice(0, 5));
          } catch (geocodeError: any) {
            console.error('Zip Locator Error:', geocodeError);
            return resolve(null);
          }
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error('Zip Locator Error:', 'User denied the request for Geolocation.');
              break;
            case error.POSITION_UNAVAILABLE:
              console.error('Zip Locator Error:', 'Location information is unavailable.');
              break;
            case error.TIMEOUT:
              console.error('Zip Locator Error:', 'The request to get user location timed out.');
              break;
            default:
              console.error('Zip Locator Error:', 'An unknown error occurred.');
              break;
          }
        }
      );
    } else {
      console.error('Zip Locator Error: Not Supported');
      return resolve(null);
    }
  });
};

/**
 * Get lorem ipsum
 */
export const getLoremIpsum =
  (): string => `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sollicitudin tempor id eu nisl nunc mi ipsum faucibus. Nibh venenatis cras sed felis. Volutpat lacus laoreet non curabitur gravida arcu ac. Nisi lacus sed viverra tellus in hac. Morbi tincidunt augue interdum velit euismod in pellentesque massa placerat. Aliquam faucibus purus in massa tempor nec feugiat nisl pretium. Nam at lectus urna duis convallis convallis. Amet massa vitae tortor condimentum lacinia. Varius vel pharetra vel turpis nunc eget lorem dolor sed. Id venenatis a condimentum vitae sapien. Adipiscing enim eu turpis egestas pretium aenean pharetra. At erat pellentesque adipiscing commodo elit. Non sodales neque sodales ut etiam sit amet nisl purus. Leo integer malesuada nunc vel risus. Habitasse platea dictumst quisque sagittis purus. Volutpat sed cras ornare arcu dui vivamus arcu felis.
Vulputate sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar. Sed cras ornare arcu dui vivamus arcu. Quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit. Neque gravida in fermentum et sollicitudin ac orci. Arcu ac tortor dignissim convallis aenean et tortor. Nibh praesent tristique magna sit amet purus gravida quis blandit. Congue eu consequat ac felis donec et odio pellentesque. Et magnis dis parturient montes nascetur ridiculus mus mauris vitae. Blandit massa enim nec dui nunc mattis enim ut tellus. Fusce ut placerat orci nulla. Tristique senectus et netus et malesuada fames ac turpis. Massa enim nec dui nunc mattis enim. Sit amet luctus venenatis lectus magna.
Nibh ipsum consequat nisl vel. Dignissim cras tincidunt lobortis feugiat vivamus at. Magna ac placerat vestibulum lectus mauris ultrices eros in cursus. Ut porttitor leo a diam. Iaculis at erat pellentesque adipiscing commodo elit. Sed vulputate odio ut enim. Elit ut aliquam purus sit amet. Risus sed vulputate odio ut. Sed cras ornare arcu dui vivamus arcu felis bibendum ut. Duis at tellus at urna condimentum mattis pellentesque. Fermentum iaculis eu non diam phasellus vestibulum lorem sed. Viverra nibh cras pulvinar mattis nunc. Nibh cras pulvinar mattis nunc sed blandit libero volutpat. Sed velit dignissim sodales ut eu sem integer vitae. Arcu non sodales neque sodales ut etiam sit amet. Aliquam etiam erat velit scelerisque. Malesuada fames ac turpis egestas sed. Nascetur ridiculus mus mauris vitae ultricies leo. Sem integer vitae justo eget magna fermentum iaculis eu.
Nisi vitae suscipit tellus mauris. Fusce ut placerat orci nulla pellentesque dignissim enim sit. Tristique et egestas quis ipsum suspendisse ultrices gravida. Viverra suspendisse potenti nullam ac tortor vitae purus faucibus ornare. Viverra adipiscing at in tellus. Ridiculus mus mauris vitae ultricies leo integer. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt vitae. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum curabitur. Turpis egestas maecenas pharetra convallis posuere. Interdum posuere lorem ipsum dolor. Volutpat consequat mauris nunc congue nisi vitae suscipit tellus mauris.
Id velit ut tortor pretium viverra suspendisse. Nunc faucibus a pellentesque sit amet porttitor. Eget nunc lobortis mattis aliquam. Vel pharetra vel turpis nunc eget lorem. Eleifend quam adipiscing vitae proin sagittis nisl. Nec feugiat nisl pretium fusce id velit ut tortor pretium. Aliquam sem et tortor consequat id porta nibh venenatis. Sit amet consectetur adipiscing elit ut aliquam. Sem fringilla ut morbi tincidunt augue interdum velit. Bibendum arcu vitae elementum curabitur vitae. Eget felis eget nunc lobortis mattis aliquam faucibus. Volutpat maecenas volutpat blandit aliquam etiam erat velit scelerisque. Tempor id eu nisl nunc mi ipsum faucibus vitae aliquet. Tempus egestas sed sed risus pretium quam vulputate.
Aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer. Nunc scelerisque viverra mauris in aliquam. Sapien pellentesque habitant morbi tristique senectus et. Dui ut ornare lectus sit amet est placerat. In hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit. Tempus quam pellentesque nec nam aliquam. Tempor nec feugiat nisl pretium fusce id velit ut. A lacus vestibulum sed arcu non. Sapien et ligula ullamcorper malesuada proin libero nunc consequat interdum. Eget est lorem ipsum dolor sit. Arcu risus quis varius quam quisque id diam vel. Nec ultrices dui sapien eget mi proin sed libero. Aliquam sem fringilla ut morbi tincidunt. Ullamcorper eget nulla facilisi etiam dignissim diam. Varius sit amet mattis vulputate enim nulla aliquet porttitor lacus. Pharetra sit amet aliquam id diam maecenas ultricies. Varius duis at consectetur lorem donec massa sapien faucibus.
Sed nisi lacus sed viverra tellus in hac habitasse. Lobortis mattis aliquam faucibus purus in massa. Pellentesque massa placerat duis ultricies lacus. Augue interdum velit euismod in pellentesque massa placerat duis. Vestibulum lorem sed risus ultricies tristique nulla. Dolor sit amet consectetur adipiscing elit. Pulvinar etiam non quam lacus suspendisse faucibus interdum. Porta lorem mollis aliquam ut. Integer malesuada nunc vel risus commodo viverra maecenas accumsan lacus. Integer vitae justo eget magna fermentum iaculis eu non diam. At in tellus integer feugiat scelerisque varius. Velit laoreet id donec ultrices tincidunt arcu. Cras fermentum odio eu feugiat pretium nibh ipsum. Maecenas ultricies mi eget mauris pharetra et ultrices neque. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper. Semper eget duis at tellus at urna condimentum. Quisque egestas diam in arcu cursus. Ut aliquam purus sit amet luctus venenatis lectus magna fringilla. Purus faucibus ornare suspendisse sed nisi lacus sed.
Hac habitasse platea dictumst quisque. Praesent elementum facilisis leo vel fringilla est ullamcorper. Scelerisque eu ultrices vitae auctor eu augue ut lectus. Ornare quam viverra orci sagittis. Pulvinar etiam non quam lacus suspendisse faucibus. Suspendisse in est ante in nibh mauris cursus mattis molestie. In egestas erat imperdiet sed euismod nisi. A cras semper auctor neque vitae tempus quam pellentesque nec. Morbi leo urna molestie at elementum eu facilisis sed odio. Condimentum id venenatis a condimentum. Arcu ac tortor dignissim convallis aenean. Ut placerat orci nulla pellentesque dignissim enim sit amet. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae. Elementum nibh tellus molestie nunc. In hendrerit gravida rutrum quisque non tellus. Aliquam nulla facilisi cras fermentum odio. Posuere urna nec tincidunt praesent semper feugiat nibh.
Elementum nisi quis eleifend quam adipiscing vitae. Lectus arcu bibendum at varius vel pharetra vel turpis nunc. Tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada. Ornare aenean euismod elementum nisi quis. Tristique senectus et netus et. Velit sed ullamcorper morbi tincidunt ornare massa eget egestas purus. Etiam non quam lacus suspendisse. Mollis aliquam ut porttitor leo a diam sollicitudin. Enim ut tellus elementum sagittis vitae et leo. Nascetur ridiculus mus mauris vitae ultricies leo integer malesuada. Arcu non sodales neque sodales ut etiam sit. Et sollicitudin ac orci phasellus egestas. Risus commodo viverra maecenas accumsan. Tempus quam pellentesque nec nam aliquam sem et tortor. Vel quam elementum pulvinar etiam. Nibh ipsum consequat nisl vel pretium. Eget egestas purus viverra accumsan in nisl nisi scelerisque eu. Elit at imperdiet dui accumsan sit amet nulla facilisi.
Sed elementum tempus egestas sed sed risus pretium quam vulputate. Adipiscing elit duis tristique sollicitudin nibh sit. Convallis a cras semper auctor neque vitae tempus quam. Ipsum dolor sit amet consectetur. Cursus in hac habitasse platea dictumst quisque sagittis purus. Penatibus et magnis dis parturient. Felis donec et odio pellentesque diam volutpat. Ipsum dolor sit amet consectetur adipiscing elit duis. Sed sed risus pretium quam vulputate dignissim suspendisse in est. At risus viverra adipiscing at in tellus integer feugiat. Sit amet mauris commodo quis imperdiet massa. Mauris ultrices eros in cursus turpis massa tincidunt dui. Cursus metus aliquam eleifend mi in nulla posuere. Ipsum consequat nisl vel pretium lectus quam id leo. Amet nisl purus in mollis nunc. Lectus proin nibh nisl condimentum id venenatis a condimentum vitae. Commodo ullamcorper a lacus vestibulum sed arcu non odio euismod. Consectetur lorem donec massa sapien. Amet consectetur adipiscing elit duis tristique. Sapien eget mi proin sed libero.
Nulla facilisi cras fermentum odio eu feugiat pretium nibh. Non blandit massa enim nec dui. Imperdiet nulla malesuada pellentesque elit eget gravida cum sociis. Adipiscing diam donec adipiscing tristique risus. Et pharetra pharetra massa massa ultricies mi quis hendrerit dolor. A diam sollicitudin tempor id eu nisl nunc. Curabitur vitae nunc sed velit. Amet tellus cras adipiscing enim. Volutpat est velit egestas dui id ornare arcu. Non diam phasellus vestibulum lorem sed risus ultricies. Dui faucibus in ornare quam viverra. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. Nam aliquam sem et tortor. Sed blandit libero volutpat sed cras ornare arcu.
Id faucibus nisl tincidunt eget nullam non. Sit amet est placerat in egestas erat. Lacinia quis vel eros donec ac odio tempor orci dapibus. In fermentum posuere urna nec tincidunt. Venenatis tellus in metus vulputate eu scelerisque. Nec ultrices dui sapien eget mi proin. Netus et malesuada fames ac. Nunc sed velit dignissim sodales ut. Metus vulputate eu scelerisque felis imperdiet. Non odio euismod lacinia at quis risus sed vulputate odio. Non nisi est sit amet facilisis. Quis blandit turpis cursus in hac habitasse. Id ornare arcu odio ut. Ac felis donec et odio pellentesque diam volutpat commodo sed. Montes nascetur ridiculus mus mauris vitae ultricies. Morbi quis commodo odio aenean sed adipiscing diam donec. Massa eget egestas purus viverra accumsan in. Congue eu consequat ac felis donec et.
Rutrum tellus pellentesque eu tincidunt tortor aliquam. Ut sem nulla pharetra diam sit amet. Id cursus metus aliquam eleifend mi in nulla posuere sollicitudin. At tellus at urna condimentum mattis pellentesque id. Pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus at. Arcu non odio euismod lacinia at quis risus. Quis varius quam quisque id diam vel. At varius vel pharetra vel turpis nunc eget lorem dolor. Vel fringilla est ullamcorper eget nulla facilisi etiam. Nunc vel risus commodo viverra. Lorem donec massa sapien faucibus et molestie ac feugiat.
A lacus vestibulum sed arcu. Leo in vitae turpis massa sed elementum. Et malesuada fames ac turpis egestas maecenas pharetra. Senectus et netus et malesuada fames. Blandit cursus risus at ultrices mi tempus imperdiet. Facilisis gravida neque convallis a cras. Ultrices tincidunt arcu non sodales neque sodales. Leo vel orci porta non pulvinar neque laoreet. Gravida dictum fusce ut placerat orci nulla pellentesque dignissim. Scelerisque felis imperdiet proin fermentum leo vel orci porta. Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque. Mus mauris vitae ultricies leo integer malesuada. Lectus proin nibh nisl condimentum id venenatis a condimentum vitae. Enim tortor at auctor urna nunc id cursus metus. Urna duis convallis convallis tellus id interdum velit. Diam maecenas sed enim ut sem. Et netus et malesuada fames. At quis risus sed vulputate odio ut enim blandit volutpat.
A iaculis at erat pellentesque. Scelerisque fermentum dui faucibus in. Scelerisque viverra mauris in aliquam sem fringilla ut. Tellus id interdum velit laoreet id. Ac placerat vestibulum lectus mauris ultrices eros in. Ac turpis egestas maecenas pharetra convallis posuere morbi. Nulla pellentesque dignissim enim sit. Ut etiam sit amet nisl purus in. Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mi sit amet mauris commodo quis imperdiet massa tincidunt. Viverra ipsum nunc aliquet bibendum. Aliquet enim tortor at auctor. Cursus in hac habitasse platea dictumst. Aliquam purus sit amet luctus venenatis lectus magna fringilla. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Faucibus et molestie ac feugiat sed lectus vestibulum mattis. Nec sagittis aliquam malesuada bibendum arcu vitae. Metus aliquam eleifend mi in. Vitae proin sagittis nisl rhoncus mattis.
Mi proin sed libero enim sed faucibus turpis in eu. Cursus risus at ultrices mi tempus imperdiet. Purus sit amet volutpat consequat. Consequat semper viverra nam libero justo laoreet sit. Vestibulum sed arcu non odio euismod lacinia at. Consectetur libero id faucibus nisl tincidunt eget. Quis auctor elit sed vulputate mi. Vivamus at augue eget arcu dictum varius duis at consectetur. In hendrerit gravida rutrum quisque. Suscipit tellus mauris a diam maecenas sed enim ut. Tellus orci ac auctor augue mauris. Est lorem ipsum dolor sit. Nisi scelerisque eu ultrices vitae auctor eu. Nibh ipsum consequat nisl vel pretium lectus quam. Commodo odio aenean sed adipiscing. Vivamus at augue eget arcu dictum. Facilisis volutpat est velit egestas dui. Malesuada fames ac turpis egestas integer eget aliquet nibh. Nec ultrices dui sapien eget.
`;

/**
 * Scrolls to a specific ID on the page. If an offset is specified (x or y), it will use
 * window.scrollTo which allows to specify an offset. Otherwise, it will use element.scrollIntoView.
 * @param id - The ID of the node you want to scroll to
 * @param {Object} [options] - The options to configure scrolling behavior
 * @property {number} [options.offsetTop=0] - The Y offset to apply when scrolling to the element
 * @property {number} [options.offsetLeft=0] - The X offset to apply when scrolling to the element
 * @property {string} [options.behavior=smooth] - The scrolling behavior ('smooth' | 'instant' | 'auto')
 */
export const scrollTo = (
  id: string,
  options?: { offsetTop?: number; offsetLeft?: number; behavior?: ScrollBehavior }
) => {
  const { offsetTop = 0, offsetLeft = 0, behavior = 'smooth' } = options || {};
  const element = document.getElementById(id);
  const compiledTop = element && element.offsetTop + offsetTop;
  const compiledLeft = element && element.offsetLeft + offsetLeft;
  if (offsetTop || offsetLeft) {
    setTimeout(() => {
      window.scrollTo({ top: compiledTop, left: compiledLeft, behavior });
    }, 0);
  } else {
    element.scrollIntoView({ behavior });
  }
};

/**
 * Will scroll to the top of the page, or to
 * @param {Object} [options] - The options to configure scrolling behavior
 * @property {smooth|instant|auto} [options.behavior=smooth] - The scrolling behavior
 */
export const scrollTop = (options?: { behavior?: ScrollBehavior }) => {
  const { behavior = 'smooth' } = options || {};
  (document as any).querySelector('body')?.scrollIntoView({ behavior });
};

/**
 * Takes a phone string and formats it properly.
 * @param {string} phoneNumberString - The phone number to format
 * @returns {string} The formatted phone number
 *
 * @TODO Currently, it appears to only support +1 country code. We should add better
 * internationalization to this, if we extend our offerings outside North America.
 */
export const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const matchFirst = cleaned.match(/^(1|)?(\d{3})$/);
  const matchSecond = cleaned.match(/^(1|)?(\d{3})(\d{3})$/);
  const matchFull = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  let formatted = phoneNumberString || '';

  if (matchFull) {
    const intlCode = matchFull[1] ? '+1 ' : '';
    formatted = [intlCode, '(', matchFull[2], ') ', matchFull[3], '-', matchFull[4]].join('');
  }
  if (matchSecond) {
    const intlCode = matchSecond[1] ? '+1 ' : '';
    formatted = [intlCode, '(', matchSecond[2], ') ', matchSecond[3], '-'].join('');
  }
  if (matchFirst) {
    const intlCode = matchFirst[1] ? '+1 ' : '';
    formatted = [intlCode, '(', matchFirst[2], ') '].join('');
  }

  return formatted.substring(0, (formatted || '')[0] === '+' ? 17 : 14);
};

/**
 * Submits a web-to-lead lead to Salesforce. Due to the rigidity of web-to-lead submissions,
 * this method does the heavy lifting by injecting a hidden iframe with a form in the DOM, adds
 * all the specified fields to it, and then submits it. It will also extract all marketing attribution
 * cookies and pass them along if set.
 * @param {Object} fields - An object with all SF fields: { [sfFieldId]: value }
 * @param {string} [iframeId=SALESFORCE_HIDDEN_IFRAME] - The ID to use for the form, instead of the default one
 */
export const submitSalesforceLead = (fields = {}, iframeId?: string) => {
  const defaultFields = {
    oid: process.env.REACT_APP_SALESFORCE_ID,
    retURL: window.location.href,
  };
  const marketing_fields = omitBy(
    {
      utm_source__c: getCookie('utm_source'),
      utm_medium__c: getCookie('utm_medium'),
      utm_campaign__c: getCookie('utm_campaign'),
      utm_term__c: getCookie('utm_term'),
      utm_content__c: getCookie('utm_content'),
      GCLID__c: getCookie('gclid'),
      GCLSRC__c: getCookie('gsource'),
    },
    (f) => f === null || f === undefined || f === ''
  );
  const allFields = { ...marketing_fields, ...defaultFields, ...fields };
  const customHiddenIframeId = iframeId || 'SALESFORCE_HIDDEN_IFRAME';
  if (!document.getElementById(customHiddenIframeId)) {
    const theIframe = document.createElement('iframe');
    theIframe.id = customHiddenIframeId;
    theIframe.name = customHiddenIframeId;
    theIframe.src = 'about:blank';
    theIframe.style.display = 'none';
    document.body.appendChild(theIframe);
  }

  const form = document.createElement('form');
  form.method = 'POST';
  form.action = process.env.REACT_APP_SALESFORCE_URL;
  form.setAttribute('target', customHiddenIframeId);

  for (const fieldName in allFields) {
    const theInput = document.createElement('input');
    theInput.name = fieldName;
    theInput.value = allFields[fieldName];
    theInput.setAttribute('type', 'hidden');
    form.appendChild(theInput);
  }

  document.body.appendChild(form);
  form.submit();
};

/**
 * Recursively renames all keys of an object using the provided function.
 * @example renameKeys({ a, b: { c }}, (k) => `${k}X`)
 * // returns { aX, bX: { cX } }
 * @param {Object} obj - The object to rename keys for
 * @param {(key: string): string} fn - The function to use to rename keys
 * @returns {Object} Object with renamed keys
 */
export const renameKeys = (obj, fn: (k: string) => string) => {
  if (Array.isArray(obj)) return obj.map((o) => renameKeys(o, fn));
  else if (typeof obj === 'object' && obj !== null)
    return Object.entries(obj).reduce((r, [k, v]) => ({ ...r, [fn(k)]: renameKeys(v, fn) }), {});
  else return obj;
};

/**
 * Retrieves device data using Braintree's data-collector.
 * @param {string} authToken - the BT authorization token retrieved from OG
 * @returns {string} the device data
 */
export const getDeviceData = async (authToken: string) => {
  return new Promise((resolve) => {
    import('braintree-web').then((BT) => {
      const collectDeviceData = (token) => {
        BT.client.create(
          {
            authorization: token,
          },
          (clientError, clientInstance) => {
            if (clientError) {
              console.log('client instance error', clientError);
              return resolve(null);
            }

            BT.dataCollector.create({ client: clientInstance }, (collectorError, dataCollectorInstance) => {
              if (collectorError) {
                console.log('data collector error', collectorError);
                return resolve(null);
              }
              return resolve(dataCollectorInstance.deviceData);
            });
          }
        );
      };
      return collectDeviceData(authToken);
    });
  });
};

/**
 * Checks if apple pay is supported on the user's client (browser/platform/etc).
 * @param {string} authToken - the BT authorization token retrieved from OG
 * @returns {boolean} if apple pay if supported on the user's device
 */
export const isApplePaySupported = async (authToken: string) => {
  return new Promise((resolve) => {
    import('braintree-web').then((BT) => {
      const applePaySession = (window as any).ApplePaySession;
      if (applePaySession && applePaySession.canMakePayments()) {
        BT.client.create({ authorization: authToken }, (clientErr, clientInstance) => {
          if (clientErr) {
            console.error('Cannot initialize braintree for apple pay.');
            return resolve(false);
          }

          BT.applePay.create(
            {
              client: clientInstance,
            },
            (applePayErr, applePayInstance) => {
              if (applePayErr) {
                console.error('Error creating applePayInstance:', applePayErr);
                return resolve(false);
              }

              applePaySession
                .canMakePaymentsWithActiveCard(applePayInstance?.merchantIdentifier)
                .then((canMakePaymentsWithActiveCard) => {
                  if (canMakePaymentsWithActiveCard) {
                    return resolve(true);
                  } else {
                    console.error('Cannot make payment with active card.');
                    return resolve(false);
                  }
                })
                .catch(() => {
                  return resolve(false);
                });
            }
          );
        });
      } else {
        return resolve(false);
      }
    });
  });
};

/**
 * Parses a JWT token and returns the payload.
 * @param token - The JWT token to parse
 * @returns The payload of the JWT token
 */
export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const isTypeformId = (action: string) => {
  if (!action) {
    return false;
  }
  return action.toLowerCase().startsWith('typeform-id=');
};

export const getTypeFormId = (action: string) => {
  if (!isTypeformId(action)) return '';
  return action.slice('typeform-id='.length);
};
