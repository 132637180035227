import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import moment from 'moment';

import { routes } from 'utils';
import { formatCurrency, getGenderLabel } from 'utils/helpers';
import { useUser } from 'utils/hooks';

import { useGetClientListQuery, useLazyGetGigMultiplePricingQuery } from 'redux/apis/OG/spa';
import { RequestType, useSpaSelfServeFlowSlice } from 'redux/features/spa/selfServe';

import { SkeletonProvider, useModals, useNavigate } from '@zeel-dev/zeel-ui';

import { SourcePricingItemType, SourcePricingMapType } from '../../../../redux/apis/OG/booking';
import ModalEditCartRequest from '../../modals/EditCartRequest';
import Cart, { CartItem, CartLineItem, CartProps } from '../Cart';

export type SpaCartProps = {
  cartProps?: Partial<CartProps>;
  priceOverride?: {
    byId: Record<string, number>;
    total: number;
  };
  onLoadingStateChange?: (b: boolean) => void;
  pricingVersionHash?: number;
};

/**
 * Layout for the SPA Self Serve flow.
 * Includes a timeline, cart, and how it works section.
 */
const SpaCart: FC<SpaCartProps> = ({ cartProps, priceOverride, onLoadingStateChange, pricingVersionHash }) => {
  const navigate = useNavigate();
  const { openModal } = useModals();
  const { isAuthenticated, isLoading: isUserLoading, isTempMember } = useUser();
  const {
    actions: { requestDeleted, cartToggledStateChanged },
    selectors: { useRequestsSelector, useCartToggledSelector, useBookingPayloadSelector },
  } = useSpaSelfServeFlowSlice();
  const [getSpaRequestsPricing] = useLazyGetGigMultiplePricingQuery();

  const bookingPayload = useBookingPayloadSelector();

  const [pricingData, setPricingData] = useState<{
    pricings: {
      pricing: SourcePricingMapType;
    }[];
    pricing_list: SourcePricingItemType[];
  }>(null);
  const [fetchingPricing, setFetchingPricing] = useState<boolean>(false);

  useEffect(() => {
    onLoadingStateChange?.(fetchingPricing);
  }, [fetchingPricing]);

  // Fetch pricing on booking payload change
  useEffect(() => {
    const fct = async () => {
      if (!isAuthenticated || fetchingPricing || !bookingPayload) return;
      setFetchingPricing(true);
      try {
        const _pricingData = await getSpaRequestsPricing(bookingPayload).unwrap();
        setPricingData(_pricingData);
      } catch (errors: any) {
        console.error(errors);
        setPricingData(null);
      }
      setFetchingPricing(false);
    };
    fct();
  }, [JSON.stringify(bookingPayload || {}), pricingVersionHash]);

  /** States **/
  const cartToggled = useCartToggledSelector();

  /** Queries **/
  const { data: clientsList } = useGetClientListQuery(undefined, {
    skip: !isAuthenticated || isUserLoading || isTempMember,
  });

  /** Selectors **/
  const requests = useRequestsSelector();

  /** Handlers **/
  const handleEditItem = useCallback((item: CartItem) => {
    openModal({
      element: <ModalEditCartRequest requestId={item.id} />,
    });
  }, []);

  const handleDeleteItem = useCallback((item: CartItem) => {
    requestDeleted(item.id);
  }, []);

  const handleProceed = useCallback(() => {
    navigate(routes.SPA_SELF_SERVE.LOCATION_INFO());
  }, []);

  const getTimeRangeLabel = useCallback((request: RequestType) => {
    const startTime = moment(request.requestedDateTime?.label);
    const endTime = moment(startTime).add(request.duration, 'minutes');
    return `${startTime.format('h:mma')} - ${endTime.format('h:mma')}`;
  }, []);

  /** Memoized Values **/
  const savedSpaGroups = useMemo(() => {
    const options = [];
    (clientsList || []).forEach((client) => {
      (client.addresses || []).forEach((address) => {
        options.push({
          id: `${client.id}.${address.id}`,
          name: client.name,
          description: [address.address1, address.address2, address.city, address.state].filter((a) => a).join(', '),
        });
      });
    });
    return options;
  }, [clientsList]);

  const cartItems = useMemo(() => {
    return [...(requests || [])].map((r) => ({
      id: r.id,
      ...(r.spa ? { groupId: `${r.spa.clientId}.${r.spa.locationId}` } : {}),
      label: moment(r.requestedDateTime?.label).format('dddd, MMMM D, YYYY'),
      content: (
        <>
          <p>
            {getTimeRangeLabel(r)} | {(r.duration / 60) * r.quantity} Hour
            {(r.duration / 60) * r.quantity > 1 ? 's' : ''}
          </p>
          <p>
            {r.quantity} Therapist{r.quantity > 1 ? 's' : ''} | {getGenderLabel(r.gender, 'No Gender Preference')}
          </p>
          {(r.estimatedPrice || priceOverride?.byId?.[r.id]) && (
            <p>{formatCurrency(priceOverride?.byId?.[r.id] || r.estimatedPrice)}</p>
          )}
        </>
      ),
    })) as CartItem[];
  }, [requests, getTimeRangeLabel]);

  const lineItems = useMemo(() => {
    const hourValue = cartItems.reduce((acc, item) => {
      const request = requests.find((r) => r.id === item.id);
      return acc + request.quantity * (request.duration / 60);
    }, 0);
    const rateValue =
      Number(
        pricingData?.pricings?.[0]?.pricing?.pre_credit_sub_total_with_tip?.prc ||
          Number(pricingData?.pricings?.[0]?.pricing?.total_no_credit?.prc || 0) -
            Number(pricingData?.pricings?.[0]?.pricing?.tax?.prc || 0) ||
          0
      ) /
      (requests?.[0]?.duration / 60);
    const subtotalValue = pricingData?.pricing_list?.find((item) => item.type === 'subtotal')?.prc;
    const taxValue = pricingData?.pricing_list
      ?.filter((item) => item.type === 'tax')
      ?.reduce((acc, item) => acc + Number(item.prc || 0), 0);
    const creditValue = pricingData?.pricing_list
      ?.filter((item) => item.type === 'credit_user')
      ?.reduce((acc, item) => acc + Number(item.prc || 0), 0);
    const totalValue = pricingData?.pricing_list?.find((item) => item.type === 'total')?.prc;
    const _items: CartLineItem[] = [
      {
        label: 'Total Hours',
        value: hourValue > 0 ? hourValue.toFixed(1) : '-',
      },
      {
        label: 'Hourly Rate',
        value: rateValue > 0 ? formatCurrency(rateValue) : '-',
      },
      ...(taxValue > 0 || creditValue > 0
        ? [
            {
              label: 'Subtotal',
              value: formatCurrency(subtotalValue),
              modifier: {
                line: 'after',
              },
            },
          ]
        : []),
      ...(creditValue > 0
        ? [
            {
              label: 'Credit',
              value: formatCurrency(-creditValue),
              modifier: {
                credit: true,
                heavy: true,
              },
            },
          ]
        : []),
      ...(taxValue > 0
        ? [
            {
              label: 'Tax',
              value: formatCurrency(taxValue),
            },
          ]
        : []),
      {
        label: 'Total',
        value: formatCurrency(totalValue),
        modifier: {
          heavy: true,
          line: 'before',
        },
      },
    ];
    return _items;
  }, [cartItems, requests, pricingData]);

  return (
    <SkeletonProvider loading={fetchingPricing}>
      <Cart
        containerType={'fixed'}
        toggled={cartToggled}
        groups={savedSpaGroups}
        items={cartItems}
        lineItems={lineItems}
        actions={[
          {
            label: 'Check Out',
            onClick: () => {
              cartToggledStateChanged(false);
              handleProceed();
            },
            buttonProps: { type: 'accent', disabled: !requests?.length },
          },
          {
            label: 'Add More',
            buttonProps: {
              type: 'secondary',
            },
            onClick: () => {
              cartToggledStateChanged(false);
              navigate(routes.SPA_SELF_SERVE.REQUESTS());
            },
          },
        ]}
        onItemEdit={handleEditItem}
        onItemDelete={handleDeleteItem}
        onClose={() => cartToggledStateChanged(false)}
        {...cartProps}
      />
    </SkeletonProvider>
  );
};

export default SpaCart;
